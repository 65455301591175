import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from 'containers/PrivateRoute';
import Loader from 'components/Loader';
import routes from 'routes';

import Users from 'cms/Users/Loadable';
import CreateUser from 'cms/Users/Forms/Create/Loadable';
import UpdateUser from 'cms/Users/Forms/Update/Loadable';
import VisibleOnSite from 'cms/VisibleOnSite/Loadable';
import Products from 'cms/Products/Loadable';
import UpdateProductForm from 'cms/Products/EditProduct/UpdateProductForm/Loadable';
import ProductDiscounts from 'cms/Products/EditProduct/Discounts/Loadable';
import ProductAttributes from 'cms/Products/EditProduct/Attributes/Loadable';
import ProductPhotos from 'cms/Products/EditProduct/Photos/Loadable';
import ProductSticker from 'cms/Products/EditProduct/Sticker/Loadable';
import CatalogProductSticker from 'cms/Products/EditProduct/CatalogProductSticker/Loadable';
import CreateProduct from 'cms/Products/CreateProduct/Loadable';
import ProductsCreateAttributes from 'cms/Products/MoreTools/Attributes/Loadable';
import Categories from 'cms/Categories/Loadable';
import AddCategory from 'cms/Categories/AddCategory/Loadable';
import EditCategory from 'cms/Categories/EditCategory/Loadable';
import WebMargins from 'cms/WebMargins/Loadable';
import WebMarginsByCategory from 'cms/WebMarginsByCategory/Loadable';
import AddWebMargins from 'cms/WebMargins/Forms/Create/Loadable';
import EditWebMargins from 'cms/WebMargins/Forms/Update/Loadable';
import RecommendedPrice from 'cms/RecommendedPrice/Loadable';
import AddRecommendedPrice from 'cms/RecommendedPrice/Forms/Create/Loadable';
import EditRecommendedPrice from 'cms/RecommendedPrice/Forms/Update/Loadable';
import Brands from 'cms/Brands/Loadable';
import Attributes from 'cms/Attributes/Loadable';
import UploadPricelist from 'cms/UploadPricelist/Loadable';
import LinkingCategories from 'cms/LinkingCategories/Loadable';
import LinkingProducts from 'cms/LinkingProducts/Loadable';
import NewProducts from 'cms/NewProducts/Loadable';
import ActivityLog from 'cms/ActivityLog/Loadable';
import AuthDiscounts from 'cms/AuthDiscounts/Loadable';
import CreateAuthDiscount from 'cms/AuthDiscounts/Forms/Create/Loadable';
import UpdateAuthDiscount from 'cms/AuthDiscounts/Forms/Update/Loadable';
import DiscountsTypes from 'cms/DiscountsTypes/Loadable';
import Orders from 'cms/Orders/Loadable';
import ShowOrder from 'cms/Orders/ShowOrder/Loadable';
import Invoices from 'cms/Invoices/Loadable';
import ShowInvoice from 'cms/Invoices/ShowInvoice/Loadable';
import InvoicesEmails from 'cms/Invoices/Emails/Loadable';
import InvoicesBanks from 'cms/Invoices/Banks/Loadable';
import InvoicesDatas from 'cms/Invoices/Datas/Loadable';
import Banners from 'cms/Banners/Loadable';
import CreateBanner from 'cms/Banners/CreateBanner/Loadable';
import UpdateBanner from 'cms/Banners/UpdateBanner/Loadable';
import HomeScreenProducts from 'cms/HomeScreen/Products/Loadable';
// import HomeScreenSearchResult from 'views/SearchResult/Loadable';
import HomeScreenCategories from 'cms/HomeScreen/Categories/Loadable';
import HomeScreenSections from 'cms/HomeScreen/Sections/Loadable';
import HomeScreenCreateSection from 'cms/HomeScreen/Sections/CreateSection/Loadable';
import HomeScreenShowSection from 'cms/HomeScreen/Sections/ShowSection/Loadable';
import News from 'cms/News/Loadable';
import AddNews from 'cms/News/Forms/CreateNews/Loadable';
import EditNews from 'cms/News/Forms/UpdateNews/Loadable';
import FrontendRoutes from './FrontendRoutes';
import CmsAuth from 'cms/Auth/Loadable';
import Authorization from 'cms/Authorization/Loadable';
import Newsletter from 'cms/Newsletter/Loadable';
import Notifications from 'cms/Notifications/Loadable';
import Ratings from 'cms/Ratings/Loadable';
import ShowRating from 'cms/Ratings/ShowRating/Loadable';
import QuestionsAndAnswers from 'cms/QuestionsAndAnswers/Loadable';
import ShowQuestionsAndAnswers from 'cms/QuestionsAndAnswers/Show/Loadable';
import ProductsExport from 'cms/ProductsExport/Loadable';
import ProductsExportNewClient from 'cms/ProductsExport/Clients/NewClient/Loadable';
import ProductsImportCategoryToCategory from 'cms/ProductsImport/CategoryToCategory/Loadable';
import ProductsImportProductToCategory from 'cms/ProductsImport/ProductToCategory/Loadable';
import Actions from 'cms/Actions/Loadable';
import AddCondition from 'cms/JakovLager/Forms/Create/Loadable';
import AddExclusion from 'cms/JakovLager/Forms/Remove/Loadable';
import RemoveCondition from 'cms/JakovLager/Forms/Remove/Loadable';

import { makeSelectModules } from './selectors';
import { getItem } from 'utils/localStorage';
import OurSuppliers from 'cms/OurSuppliers';
import EOffer from 'cms/EOffer';
import CatalogSticker from 'cms/CatalogSticker/Loadable';
import OurSuppliersProducts from 'cms/OurSuppliersProducts';
import EOfferImport from 'cms/EOfferImport';
import ApiClient from 'cms/ApiClient';
import LandingPage from 'cms/LandingPage';
import SEO from 'cms/SEO';
import PromoCode from 'cms/PromoCode/Loadable';
import JakovLager from 'cms/JakovLager';
import EditCondition from 'cms/JakovLager/Forms/Update';
import EditExclusion from 'cms/JakovLager/Forms/ExclusionUpdate';
export default function Routes() {
  const selectModules = useSelector(makeSelectModules());
  const modules = getItem('modules') || selectModules;

  return (
    <Switch>
      <PrivateRoute
        exact
        path={routes.CMS_USERS}
        component={Users}
        role="view-user"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_USER}
        component={CreateUser}
        role="create-user"
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_USER}
        component={UpdateUser}
        role="update-user"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ACTIVITY_LOG}
        component={ActivityLog}
        role="view-activity"
      />
      <PrivateRoute
        exact
        path={routes.CMS_LINKING_PRODUCTS}
        component={LinkingProducts}
        role="connect-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_NEWS_PRODUCTS}
        component={NewProducts}
      />
      <PrivateRoute
        exact
        path={routes.CMS_LINKING_CATEGORIES}
        component={LinkingCategories}
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS_EXPORT}
        component={ProductsExport}
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS_EXPORT_ADD_CLIENT}
        component={ProductsExportNewClient}
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS_IMPORT_CATEGORY_TO_CATEGORY}
        component={ProductsImportCategoryToCategory}
        module="ProductImport"
        role="map-products-import"
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS_IMPORT_PRODUCT_TO_CATEGORY}
        component={ProductsImportProductToCategory}
        module="ProductImport"
        role="map-products-import"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ATTRIBUTES}
        component={Attributes}
        role="view-attribute"
      />
      <PrivateRoute
        exact
        path={routes.CMS_BRANDS}
        component={Brands}
        role="view-brand"
      />
      <PrivateRoute
        exact
        path={routes.CMS_DISCOUNTS_TYPES}
        component={DiscountsTypes}
        role="view-discount-type"
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS_VISIBLE_ON_SITE}
        component={VisibleOnSite}
        role="view-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_E_OFFER}
        component={EOffer}
        role="view-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_E_OFFER_IMPORT}
        component={EOfferImport}
        role="view-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS}
        component={Products}
        role="view-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_PRODUCT}
        component={CreateProduct}
        role="create-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCT}
        component={UpdateProductForm}
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCT_DISCOUNT}
        component={ProductDiscounts}
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCT_ATTRIBUTES}
        component={ProductAttributes}
        role="view-product-attribute"
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCT_PHOTOS}
        component={ProductPhotos}
        role="view-product-image"
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCT_STICKER}
        component={ProductSticker}
      />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCT_CATALOG_STICKER}
        component={CatalogProductSticker}
      />
      <PrivateRoute exact path={routes.CMS_PROMO_CODE} component={PromoCode} />
      <PrivateRoute
        exact
        path={routes.CMS_PRODUCTS_CREATE_ATTRIBUTE}
        component={ProductsCreateAttributes}
      />
      <PrivateRoute
        exact
        path={routes.CMS_CATEGORIES}
        component={Categories}
        role="view-category"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_CATEGORY}
        component={AddCategory}
        role="create-category"
      />
      <PrivateRoute exact path={routes.CMS_CATEGORY} component={EditCategory} />
      <PrivateRoute
        exact
        path={routes.CMS_RECOMMENDED_PRICE}
        component={RecommendedPrice}
        role="view-recommended-price"
      />
      <PrivateRoute
        exact
        path={routes.CMS_JAKOV_LAGER}
        component={JakovLager}
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_RECOMMENDED_PRICE}
        component={AddRecommendedPrice}
        role="create-recommended-price"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_CONDITION}
        component={AddCondition}
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_EXCLUSION}
        component={AddExclusion}
      />
      <PrivateRoute
        exact
        path={routes.CMS_REMOVE_CONDITION}
        component={RemoveCondition}
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_CONDITION}
        component={EditCondition}
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_EXCLUSION}
        component={EditExclusion}
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_RECOMMENDED_PRICE}
        component={EditRecommendedPrice}
        role="update-recommended-price"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ORDERS}
        component={Orders}
        role="view-order"
      />
      <PrivateRoute
        exact
        path={routes.CMS_SHOW_ORDER}
        component={ShowOrder}
        role="view-order"
      />
      <PrivateRoute
        exact
        path={routes.CMS_INVOICES}
        component={Invoices}
        role="view-proforma-invoice"
      />
      <PrivateRoute
        exact
        path={routes.CMS_INVOICE}
        component={ShowInvoice}
        role="view-proforma-invoice"
      />
      <PrivateRoute
        exact
        path={routes.CMS_INVOICES_EMAILS}
        component={InvoicesEmails}
        role="view-proforma-invoice-info"
      />
      <PrivateRoute
        exact
        path={routes.CMS_INVOICES_BANKS}
        component={InvoicesBanks}
        role="view-proforma-invoice-info"
      />
      <PrivateRoute
        exact
        path={routes.CMS_INVOICES_DATA}
        component={InvoicesDatas}
        role="view-proforma-invoice-info"
      />
      <PrivateRoute
        exact
        path={routes.CMS_AUTHORIZATION}
        component={Authorization}
        module="Authorization"
        role="view-role"
      />
      <PrivateRoute
        exact
        path={routes.CMS_CATEGORY_WEB_MARGINS}
        component={WebMarginsByCategory}
      />
      <PrivateRoute
        exact
        path={routes.CMS_WEB_MARGINS}
        component={WebMargins}
        module="WebMargin"
        role="view-web-margin"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_WEB_MARGIN}
        component={AddWebMargins}
        module="WebMargin"
        role="create-web-margin"
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_WEB_MARGIN}
        component={EditWebMargins}
        module="WebMargin"
        role="update-web-margin"
      />
      <PrivateRoute
        exact
        path={routes.CMS_NEWSLETTER}
        component={Newsletter}
        module="Frontend"
        role="view_newsletter-product"
      />
      <PrivateRoute
        exact
        path={routes.CMS_NOTIFICATIONS}
        component={Notifications}
      />
      <PrivateRoute
        exact
        path={routes.CMS_AUTH_DISCOUNTS}
        component={AuthDiscounts}
        module="B2C"
        role="view-authenticated-discount"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_AUTH_DISCOUNTS}
        component={CreateAuthDiscount}
        module="B2C"
        role="create-authenticated-discount"
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_AUTH_DISCOUNTS}
        component={UpdateAuthDiscount}
        module="B2C"
        role="update-authenticated-discount"
      />
      <PrivateRoute
        exact
        path={routes.CMS_UPLOAD_PRICELIST}
        component={UploadPricelist}
        module="Pricelist"
        role="upload-pricelist"
      />
      <PrivateRoute
        exact
        path={routes.CMS_API_CLIENT}
        component={ApiClient}
        module="Pricelist"
        role="upload-pricelist"
      />
      <PrivateRoute
        exact
        path={routes.CMS_LANDING_PAGE}
        component={LandingPage}
        module="Pricelist"
        role="upload-pricelist"
      />

      <PrivateRoute
        exact
        path={routes.CMS_SEO}
        component={SEO}
        module="Pricelist"
        role="upload-pricelist"
      />
      <Route
        exact
        path={routes.CMS_OUR_SUPPLIERS}
        component={OurSuppliers}
        // role="our-suppliers"
      />
      <Route
        exact
        path={routes.CMS_OUR_SUPPLIERS_PRODUCTS}
        component={OurSuppliersProducts}
        // role="our-suppliers"
      />
      <Route
        exact
        path={routes.CMS_CATALOG_STICKERS}
        component={CatalogSticker}
        // role="our-suppliers"
      />
      <PrivateRoute
        exact
        path={routes.CMS_RATINGS}
        component={Ratings}
        module="Frontend"
        role="view-rating"
      />
      <PrivateRoute
        exact
        path={routes.CMS_RATING}
        component={ShowRating}
        module="Frontend"
        role="view-rating"
      />
      <PrivateRoute
        exact
        path={routes.CMS_QUESTIONS_AND_ANSWERS}
        component={QuestionsAndAnswers}
        module="Frontend"
        role="view-question"
      />
      <PrivateRoute
        exact
        path={routes.CMS_SHOW_QUESTIONS_AND_ANSWERS}
        component={ShowQuestionsAndAnswers}
        module="Frontend"
        role="view-question"
      />
      <PrivateRoute
        exact
        path={routes.CMS_BANNERS}
        component={Banners}
        module="Banner"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_BANNER}
        component={CreateBanner}
        module="Banner"
        role="create-banner"
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_BANNER}
        component={UpdateBanner}
        module="Banner"
        role="update-banner"
      />
      <PrivateRoute
        exact
        path={routes.CMS_HOME_SCREEN_PRODUCTS}
        component={HomeScreenProducts}
        module="Home"
        role="update-home-page"
      />
      <PrivateRoute
        exact
        path={routes.CMS_HOME_SCREEN_CATEGORIES}
        component={HomeScreenCategories}
        module="Home"
        role="update-home-page"
      />
      <PrivateRoute
        exact
        path={routes.CMS_HOME_SCREEN_SECTIONS}
        component={HomeScreenSections}
        module="Home"
        role="update-home-page"
      />
      <PrivateRoute
        exact
        path={routes.CMS_HOME_SCREEN_ADD_SECTION}
        component={HomeScreenCreateSection}
        module="Home"
        role="update-home-page"
      />
      <PrivateRoute
        exact
        path={routes.CMS_HOME_SCREEN_SECTION}
        component={HomeScreenShowSection}
        module="Home"
        role="update-home-page"
      />
      <PrivateRoute
        exact
        path={routes.CMS_NEWS}
        component={News}
        module="News"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ADD_NEWS}
        component={AddNews}
        module="News"
        role="create-post"
      />
      <PrivateRoute
        exact
        path={routes.CMS_EDIT_NEWS}
        component={EditNews}
        module="News"
        role="update-post"
      />
      <PrivateRoute
        exact
        path={routes.CMS_ACTIONS}
        component={Actions}
        module="Frontend"
        role="view_category-discount-display"
      />
      {modules.length ? <CmsAuth /> : <Loader />}
    </Switch>
  );
}
