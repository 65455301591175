import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from 'utils/request';
import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import {
  getProducts,
  setProducts,
  getProduct,
  getProductError,
  setProduct,
  sendPhotosSuccess,
  createProductSuccess,
  createProductErrors,
  updateProductSuccess,
  updateProductError,
  setAttributes,
  getAttributes,
  getCategoryAttributes,
  setCategoryAttributes,
  createBrandError,
  createMainPhoto,
  getPhotos,
  setPhotos,
  setSticker,
  setPassProducts,
  postPassProducts,
  setAttributeValues,
  setCatalogSticker
} from './actions';
import types from './constants';
import { makeSelectParams } from './selectors';
import messages from '../messages';
import routes from 'routes';
import { convertToRaw } from 'draft-js';

const BASE_URL = 'admin/products';

export function* fetchProducts({ payload }) {
  try {
    let tmpPayload = payload;

    const response = yield call(request, {
      url: `${BASE_URL}?include=mainCategory,mainImage`,
      method: 'get',
      params: {
        ...tmpPayload,
        attributes_ids: undefined,
        attributes_values: undefined
      }
    });
    yield put(setProducts(response));
  } catch (error) {}
}

export function* fetchProduct({ product_id }) {
  try {
    const response = yield call(request, {
      url: `${BASE_URL}/${product_id}?include=mainCategory,mainImage`,
      method: 'get'
    });

    try {
      const seo = yield call(request, {
        url: `/seo-products/${response.data.slug}`,
        method: 'get'
      });
      response.data.seo = seo.data;
    } catch {}

    yield put(setProduct(response.data));
  } catch (error) {
    if (error.status === 404) yield put(getProductError(messages.product404));
  }
}

export function* createProduct({
  payload,
  photos,
  main_photo,
  meta: { setErrors }
}) {
  try {
    let data = payload;
    data.description = data.description
      ? JSON.stringify(convertToRaw(data.description.getCurrentContent()))
      : '';
    const res = yield call(request, {
      url: BASE_URL,
      method: 'post',
      data
    });

    if (res) {
      let id = res.data.product.id;
      if (photos.length) yield put(sendPhotosSuccess(id, photos));
      if (main_photo) yield put(createMainPhoto(id, main_photo));

      yield put(push(routes.CMS_PRODUCTS));
      yield put(createProductSuccess());
      yield put(enqueueSnackbar({ message: res.data.message }));
    }
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(createProductErrors());
  }
}

export function* uploadMainPhoto({ id, photo, method }) {
  try {
    const formData = new FormData();
    formData.append(`image`, photo, photo.name);

    const { data } = yield call(request, {
      url: `${BASE_URL}/${id}/main-images`,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (method === 'update') {
      yield put(getPhotos(id));
      yield put(enqueueSnackbar({ message: data.message }));
    }
  } catch (error) {}
}

export function* handleUploadStiker({ payload }) {
  try {
    const formData = new FormData();
    payload.ids.forEach(id => formData.append('ids[]', id));
    formData.append('sticker', payload.sticker);
    const { data } = yield call(request, {
      url: `${BASE_URL}/energy-sticker/mass-update`,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}

export function* handleUploadOneStiker({ payload }) {
  try {
    const formData = new FormData();
    formData.append('sticker', payload.sticker);
    const { data } = yield call(request, {
      url: `${BASE_URL}/${payload.id}/energy-sticker`,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}
export function* handleRemoveSticker({ payload }) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${payload.productId}/energy-sticker/delete`,
      method: 'delete'
    });
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {}
}
export function* sendPhotos({ id, payload, method }) {
  try {
    let formData = new FormData();
    payload.map(photo => formData.append(`images[]`, photo, photo.name));

    const { data } = yield call(request, {
      url: `${BASE_URL}/${id}/images`,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (method === 'update') {
      yield put(getPhotos(id));
      yield put(enqueueSnackbar({ message: data.message }));
    }
  } catch (error) {}
}

export function* handleRemovePhoto({ product_id, img_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${product_id}/images/${img_id}`,
      method: 'delete'
    });

    yield put(getPhotos(product_id));
    yield put(enqueueSnackbar({ message: res.data.message }));
  } catch (error) {}
}

export function* updateProduct({ id, payload, meta: { setErrors } }) {
  try {
    let data = payload;
    data.description = data.description
      ? JSON.stringify(convertToRaw(data.description.getCurrentContent()))
      : '';

    const res = yield call(request, {
      url: `${BASE_URL}/${id}`,
      method: 'patch',
      data
    });

    yield put(updateProductSuccess());
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(push(routes.CMS_PRODUCTS));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
    yield put(updateProductError());
  }
}

export function* updateDiscounts({
  id,
  discount_id,
  payload,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}/discounts/${discount_id}`,
      method: 'patch',
      data: payload
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    // yield put(getProduct(id));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* createDiscounts({ id, payload, meta: { setErrors } }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${id}/discounts`,
      method: 'post',
      data: payload
    });
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(getProduct(id));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* deleteDiscount({ product_id, discount_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${product_id}/discounts/${discount_id}`,
      method: 'delete'
    });
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield put(getProduct(product_id));
  } catch (error) {}
}

export function* updateDescriptions({ params, payload, close, clear }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/mass-update`,
      method: 'patch',
      params: !payload['ids[]'] && params,
      data: payload
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
    yield clear();
  } catch (error) {}
}

export function* fetchAttributes({ product_id }) {
  try {
    const attributes = yield call(request, {
      url: `${BASE_URL}/${product_id}/attributes`,
      method: 'get'
    });
    yield put(setAttributes(attributes.data));
  } catch (error) {}
}

export function* updateAttributeStatus({ product_id, attribute_id, payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${product_id}/attributes/${attribute_id}/values`,
      method: 'patch',
      data: payload
    });
    yield put(getAttributes(product_id));
  } catch (error) {}
}

export function* handleUpdateAttributeValues({
  product_id,
  attribute_id,
  value_id
}) {
  try {
    yield call(request, {
      url: `${BASE_URL}/${product_id}/attributes/${attribute_id}/values/${value_id}`,
      method: 'patch'
    });
    yield put(getAttributes(product_id));
  } catch (error) {}
}

export function* addAttributeValue({
  product_id,
  category_id,
  attribute_id,
  payload,
  close,
  meta: { setErrors, resetForm }
}) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${product_id}/attributes/${attribute_id}/values-new`,
      method: 'post',
      data: payload
    });

    yield put(getAttributes(product_id));
    yield put(getCategoryAttributes(category_id));
    yield put(enqueueSnackbar({ message: data.message }));
    yield close();
    yield call(resetForm, { value: '' });
  } catch (error) {
    if (error.status === 422)
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
  }
}

export function* fetchCategoryAttributes({ id }) {
  try {
    const res = yield call(request, {
      url: `admin/categories/${id}/attributes`,
      method: 'get'
    });

    yield put(setCategoryAttributes(res.data));
  } catch (error) {}
}

export function* massUpdateDiscounts({
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: `admin/discounts/mass-add`,
      method: 'post',
      data: payload,
      params: !payload.product_ids.length && params
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    // let productParams = yield select(makeSelectParams());
    // yield put(getProducts(productParams));
    yield close();
    yield clear();
  } catch (error) {
    if (error.status === 422)
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
  }
}

export function* handleMassActivateAttributes({ payload, params }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/attributes/mass-activate`,
      method: 'post',
      data: payload,
      params: !payload.ids.length && params
    });

    yield put(push(routes.CMS_PRODUCTS));
  } catch (error) {}
}

export function* handleMassDectivateAttributes({ payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/attributes/mass-deactivate`,
      method: 'delete',
      data: payload
    });
  } catch (error) {}
}

export function* disconnectProduct({ id, close }) {
  try {
    const res = yield call(request, {
      url: `admin/pricelist-products/${id}/connect`,
      method: 'delete'
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
  } catch (error) {}
}

export function* createAttribute({
  category_id,
  payload,
  meta: { setErrors, resetForm }
}) {
  try {
    const { data } = yield call(request, {
      url: `admin/categories/${category_id}/attributes`,
      method: 'post',
      data: payload
    });

    yield call(resetForm, { value: '', attribute_id: '' });
    yield put(getCategoryAttributes(category_id));
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* handleAddAttribute({
  category_id,
  payload,
  meta: { setErrors, resetForm }
}) {
  try {
    const { data } = yield call(request, {
      url: `admin/categories/${category_id}/attributes-new`,
      method: 'post',
      data: payload
    });

    yield call(resetForm, { attribute: '', attribute_id: '' });
    yield put(getCategoryAttributes(category_id));
    yield put(enqueueSnackbar({ message: data.message }));
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}

export function* createBrand({
  payload,
  toggleSwitcher,
  clearFormData,
  setDropdownValue
}) {
  try {
    const res = yield call(request, {
      url: 'admin/brands',
      method: 'post',
      data: payload
    });

    yield toggleSwitcher();
    yield clearFormData('');
    yield put(enqueueSnackbar({ message: res.data.message }));
    yield setDropdownValue(res.data.brand);
  } catch (error) {
    if (error.status === 422) {
      yield put(createBrandError(parseApiErrorsToFormik(error.data.errors)));
    }
  }
}

export function* fetchPhotos({ product_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${product_id}/images`,
      method: 'get'
    });

    yield put(setPhotos(res.data));
  } catch (error) {}
}

export function* fetchSticker({ product_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${product_id}`,
      method: 'get'
    });

    yield put(setSticker(res.data.energy_value_sticker));
  } catch (error) {}
}

export function* fetchCatalogSticker({ product_id }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/${product_id}`,
      method: 'get'
    });

    yield put(setCatalogSticker(res.data.catalog_stickers));
  } catch (error) {}
}

export function* getPassProducts({ payload, params, close }) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/transfer`,
      method: 'get',
      params: Object.assign({}, params, payload)
    });

    if (data.attributes.length) {
      yield put(setPassProducts(data.attributes));
    }
  } catch (error) {}
}

export function* handlePostPassProducts({ payload, params, close }) {
  console.log(payload, params, close);
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/transfer`,
      method: 'post',
      data: payload,
      params: params && params
    });

    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
    yield put(enqueueSnackbar({ message: res.data.message }));
  } catch (error) {}
}

export function* addNewAttributeValue({
  product_id,
  attribute_id,
  category_id,
  payload,
  close,
  meta: { setErrors }
}) {
  try {
    const { data } = yield call(request, {
      url: `${BASE_URL}/${product_id}/attributes/${attribute_id}/values-existing`,
      method: 'post',
      data: payload
    });

    yield put(getAttributes(product_id));
    yield put(getCategoryAttributes(category_id));
    yield put(enqueueSnackbar({ message: data.message }));
    yield close();
  } catch (error) {
    if (error.status === 422) {
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
    }
  }
}
export function* handleUploadAction({ payload, params }) {
  try {
    const data = {
      catalog_sticker_type_id: payload.catalog_sticker_type_id,
      from: payload.from,
      ids: payload.product_ids,
      to: payload.to
    };
    const res = yield call(request, {
      url: `admin/catalog-stickers/mass-add`,
      method: 'post',
      data,
      params: !data.ids.length && params
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
  } catch (error) {}
}

export function* fetchAttributeValues({ attribute_id, params }) {
  try {
    const { data } = yield call(request, {
      url: `admin/attributes/${attribute_id}/values`,
      method: 'get',
      params
    });

    yield put(setAttributeValues(data));
  } catch (error) {}
}

export function* setSpecDealerPrice({ id, payload }) {
  try {
    const { data } = yield call(request, {
      url: BASE_URL + '/action-dealer-price/' + id,
      method: 'patch',
      data: payload
    });

    yield put(enqueueSnackbar({ message: data.message }));
    yield put(setAttributeValues(data));
  } catch (error) {
    console.log(error);
  }
}

export function* addPromoCode({
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: 'admin/promocode-mass-add',
      method: 'post',
      data: payload,
      params: !payload.product_ids.length && params
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
    yield clear();
  } catch (error) {
    if (error.status === 422)
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
  }
}

export default function* productsSaga() {
  yield takeLatest(types.GET_PRODUCTS, fetchProducts);
  yield takeLatest(types.GET_PRODUCT, fetchProduct);
  yield takeLatest(types.CREATE_PRODUCT_REQUEST, createProduct);
  yield takeLatest(types.UPDATE_PRODUCT_REQUEST, updateProduct);
  yield takeLatest(types.UPDATE_DISCOUNTS_REQUEST, updateDiscounts);
  yield takeLatest(types.SEND_PHOTOS_REQUEST, sendPhotos);
  yield takeLatest(types.CREATE_MAIN_PHOTO, uploadMainPhoto);
  yield takeLatest(types.REMOVE_PHOTO, handleRemovePhoto);
  yield takeLatest(types.CREATE_DISCOUNT_REQUEST, createDiscounts);
  yield takeLatest(types.DELETE_DISCOUNT_REQUEST, deleteDiscount);
  yield takeLatest(types.UPDATE_DESCRIPTIONS, updateDescriptions);
  yield takeLatest(types.MASS_UPDATE_DISCOUNTS, massUpdateDiscounts);
  yield takeLatest(types.GET_ATTRIBUTES, fetchAttributes);
  yield takeLatest(types.UPDATE_ATTRIBUTE_STATUS, updateAttributeStatus);
  yield takeLatest(types.UPDATE_ATTRIBUTE_VALUES, handleUpdateAttributeValues);
  yield takeLatest(types.CREATE_ATTRIBUTE_VALUE_REQUEST, addAttributeValue);
  yield takeLatest(types.GET_CATEGORY_ATTRIBUTES, fetchCategoryAttributes);
  yield takeLatest(
    types.MASS_ACTIVATE_ATTRIBUTES_REQUEST,
    handleMassActivateAttributes
  );
  yield takeLatest(
    types.MASS_DEACTIVATE_ATTRIBUTES_REQUEST,
    handleMassDectivateAttributes
  );
  yield takeLatest(types.DISCONNECT_PRODUCT, disconnectProduct);
  yield takeLatest(types.CREATE_ATTRIBUTE_REQUEST, createAttribute);
  yield takeLatest(types.ADD_ATTRIBUTE_REQUEST, handleAddAttribute);
  yield takeLatest(types.CREATE_BRAND, createBrand);
  yield takeLatest(types.GET_PHOTOS, fetchPhotos);
  yield takeLatest(types.GET_STICKER, fetchSticker);
  yield takeLatest(types.GET_CATALOG_STICKER, fetchCatalogSticker);
  yield takeLatest(types.GET_PASS_PRODUCTS, getPassProducts);
  yield takeLatest(types.POST_PASS_PRODUCTS, handlePostPassProducts);
  yield takeLatest(types.ADD_NEW_ATTRIBUTE_VALUE, addNewAttributeValue);
  yield takeLatest(types.GET_ATTRIBUTE_VALUES, fetchAttributeValues);
  yield takeLatest(types.UPLOAD_STICKER, handleUploadStiker);
  yield takeLatest(types.UPLOAD_ACTION, handleUploadAction);
  yield takeLatest(types.UPLOAD_ONE_STICKER, handleUploadOneStiker);
  yield takeLatest(types.REMOVE_STICKER, handleRemoveSticker);
  yield takeLatest(types.SET_SPEC_DEALER_PRICE, setSpecDealerPrice);
  yield takeLatest(types.ADD_PROMO_CODE, addPromoCode);
}
