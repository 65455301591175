import produce from 'immer';
import types from './constants';

export const initialState = {
  products: [],
  product: null,
  productError: null,
  params: {},
  createProductIsPending: false,
  updateProductIsPending: false,
  attributes: [],
  newValueName: null,
  categoryAttributes: null,
  categoryAttributesError: null,
  createBrandError: null,
  photos: [],
  sticker: null,
  passedProducts: [],
  attributeValues: [],
  catalogSticker: []
};

/* eslint-disable default-case */
const productsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SET_PRODUCTS:
        draft.products = action.payload;
        break;
      case types.SET_PRODUCT:
        draft.product = action.payload;
        break;
      case types.GET_PRODUCT_ERROR:
        draft.productError = action.error;
        break;
      case types.CLEAR_PRODUCT_DATA:
        draft.product = null;
        draft.productError = null;
        break;
      case types.SET_PARAMS: {
        const {
          manufacturers,
          brands,
          suppliers,
          categories,
          ...rest
        } = action.payload;
        let params = {};
        if (action.payload.hasOwnProperty('manufacturers')) {
          if (action.payload.manufacturers) {
            let manufacturers = [];
            if (typeof action.payload.manufacturers === 'string') {
              manufacturers = [...action.payload.manufacturers.split(',')];
            } else {
              action.payload.manufacturers.forEach(element => {
                manufacturers.push(element.id);
              });
            }
            params = {
              ...params,
              manufacturers: manufacturers.toString()
            };
          } else {
            params = {
              ...params,
              manufacturers: undefined
            };
          }
        }
        if (action.payload.hasOwnProperty('suppliers')) {
          if (action.payload.suppliers) {
            let suppliers = [];
            if (typeof action.payload.suppliers === 'string') {
              suppliers = [...action.payload.suppliers.split(',')];
            } else {
              action.payload.suppliers.forEach(element => {
                suppliers.push(element.id);
              });
            }
            params = {
              ...params,
              suppliers: suppliers.toString()
            };
          } else {
            params = {
              ...params,
              suppliers: undefined
            };
          }
        }
        if (action.payload.hasOwnProperty('categories')) {
          if (action.payload.categories) {
            let categories = [];
            if (typeof action.payload.categories === 'string') {
              categories = [...action.payload.categories.split(',')];
            } else {
              action.payload.categories.forEach(element => {
                categories.push(element.id);
              });
            }
            params = {
              ...params,
              categories: categories.toString()
            };
          } else {
            params = {
              ...params,
              categories: undefined
            };
          }
        }
        if (action.payload.hasOwnProperty('brands')) {
          if (action.payload.brands) {
            let brands = [];
            // action.payload.brands.forEach(element => {
            //   brands.push(element.id);
            // });
            if (typeof action.payload.brands === 'string') {
              brands = [...action.payload.brands.split(',')];
            } else {
              action.payload.brands.forEach(element => {
                brands.push(element.id);
              });
            }
            params = {
              ...params,
              brands: brands.toString()
            };
          } else {
            params = {
              ...params,
              brands: undefined
            };
          }
        }
        let attributes;

        if (action.payload.attributes_ids?.length) {
          attributes = formatAttributes(
            action.payload.attributes_ids,
            action.payload.attributes_values
          );
        }
        draft.params = {
          ...params,
          ...rest,
          attributes
        };
        break;
      }
      case types.DELETE_PARAMS:
        draft.params = {};
        break;
      case types.CREATE_PRODUCT_REQUEST:
        draft.createProductIsPending = true;
        break;
      case types.CREATE_PRODUCT_SUCCESS:
      case types.CREATE_PRODUCT_ERROR:
        draft.createProductIsPending = false;
        break;
      case types.UPDATE_PRODUCT_REQUEST:
        draft.updateProductIsPending = true;
        break;
      case types.UPDATE_PRODUCT_SUCCESS:
      case types.UPDATE_PRODUCT_ERROR:
        draft.updateProductIsPending = false;
        break;
      case types.SET_ATTRIBUTES:
        draft.attributes = action.payload;
        break;
      case types.SET_NEW_VALUE_NAME:
        draft.newValueName = action.name;
        break;
      case types.CLEAR_NEW_VALUE_NAME:
        draft.newValueName = null;
        break;
      case types.GET_CATEGORY_ATTRIBUTES_ERROR:
        draft.categoryAttributesError = action.error;
        break;
      case types.SET_CATEGORY_ATTRIBUTES:
        draft.categoryAttributes = action.payload;
        break;
      case types.CLEAR_CATEGORY_ATTRIBUTES:
        draft.categoryAttributes = [];
        draft.categoryAttributesError = null;
        break;
      case types.CREATE_BRAND:
      case types.CREATE_BRAND_SUCCESS:
        draft.createBrandError = null;
        break;
      case types.CREATE_BRAND_ERROR:
        draft.createBrandError = action.error;
        break;
      case types.SET_PHOTOS:
        draft.photos = action.payload;
        break;
      case types.SET_STICKER:
        draft.sticker = action.payload;
        break;
      case types.SET_CATALOG_STICKER:
        draft.catalogSticker = action.payload;
        break;
      case types.SET_PASS_PRODUCTS:
        draft.passedProducts = action.payload;
        break;
      case types.SET_ATTRIBUTE_VALUES:
        draft.attributeValues = action.payload;
        break;
    }
  });

export default productsReducer;

function formatAttributes(attributes_ids, attributes_values) {
  // Create a map for quick lookup of attribute values by attribute id
  const valuesMap = new Map();
  attributes_values &&
    attributes_values.forEach(attributeValue => {
      valuesMap.set(
        attributeValue.attribute.id,
        attributeValue.values.map(v => v.id).join(',')
      );
    });

  // Process the attributes_ids and append values if they exist
  const result = attributes_ids
    .map(attr => {
      const values = valuesMap.get(attr.id);
      return values ? `${attr.id}(${values})` : `${attr.id}`;
    })
    .join(';');

  return result;
}
