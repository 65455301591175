import { takeLatest, call, put, takeLeading } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import request from 'utils/request';
import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import types from './constants';
import messages from '../messages';
import routes from 'routes';
import {
  setBrands,
  setCategories,
  setConditions,
  setCondition,
  getConditions as getConditionsAction,
  getExclusions as getExclusionsAction,
  setExclusions,
  setExclusion
} from './actions';

export function* fetchCategories() {
  try {
    const res = yield call(request, {
      url: 'admin/jakov-stock-categories',
      method: 'get'
    });
    yield put(setCategories(res));
  } catch (error) {}
}

export function* fetchBrands() {
  try {
    const res = yield call(request, {
      url: 'admin/jakov-stock-brands',
      method: 'get'
    });
    yield put(setBrands(res));
  } catch (error) {}
}

export function* getConditions() {
  try {
    const res = yield call(request, {
      url: 'admin/jakov-stock-conditions',
      method: 'get'
    });
    yield put(setConditions(res));
  } catch (error) {}
}

export function* getCondition(action) {
  try {
    const res = yield call(request, {
      url: `admin/jakov-stock-conditions/${action.id}`,
      method: 'get'
    });
    console.log('RES', res);
    yield put(setCondition(res));
  } catch (error) {}
}

export function* createCondition(action) {
  try {
    const res = yield call(request, {
      url: 'admin/jakov-stock-conditions',
      method: 'post',
      data: action.payload
    });
    yield put(push(routes.CMS_JAKOV_LAGER));
    yield put(getConditionsAction());
  } catch (error) {
    yield put(parseApiErrorsToFormik(error));
  }
}

export function* updateCondition(action) {
  console.log('UPDATE', action);
  try {
    const res = yield call(request, {
      url: `admin/jakov-stock-conditions/${action?.id}`,
      method: 'put',
      data: action?.payload
    });
    yield put(push(routes.CMS_JAKOV_LAGER));
    yield put(getConditionsAction());
  } catch (error) {
    yield put(parseApiErrorsToFormik(error));
  }
}

export function* deleteCondition(action) {
  try {
    const res = yield call(request, {
      url: `admin/jakov-stock-conditions/${action.id}`,
      method: 'delete'
    });
    yield put(getConditionsAction());
  } catch (error) {}
}

////admin/jakov-stock-exclusion-conditions

export function* getExclusions() {
  try {
    const res = yield call(request, {
      url: 'admin/jakov-stock-exclusion-conditions',
      method: 'get'
    });
    yield put(setExclusions(res));
  } catch (error) {}
}

export function* getExclusion(action) {
  try {
    const res = yield call(request, {
      url: `admin/jakov-stock-exclusion-conditions/${action.id}`,
      method: 'get'
    });
    yield put(setExclusion(res));
  } catch (error) {}
}

export function* createExclusion(action) {
  try {
    console.log('CREATE', action);
    const res = yield call(request, {
      url: 'admin/jakov-stock-exclusion-conditions',
      method: 'post',
      data: action.payload
    });
    yield put(push(routes.CMS_JAKOV_LAGER));
    yield put(getExclusionsAction());
  } catch (error) {}
}

export function* updateExclusion(action) {
  try {
    const res = yield call(request, {
      url: `admin/jakov-stock-exclusion-conditions/${action?.id}`,
      method: 'put',
      data: action?.payload
    });
    yield put(push(routes.CMS_JAKOV_LAGER));
    yield put(getExclusionsAction());
  } catch (error) {
    yield put(parseApiErrorsToFormik(error));
  }
}

export function* deleteExclusion(action) {
  try {
    const res = yield call(request, {
      url: `admin/jakov-stock-exclusion-conditions/${action.id}`,
      method: 'delete'
    });
    yield put(getExclusionsAction());
  } catch (error) {}
}

export default function* recommendedPricesSaga() {
  yield takeLatest(types.GET_CATEGORIES, fetchCategories);
  yield takeLatest(types.GET_BRANDS, fetchBrands);
  yield takeLatest(types.GET_CONDITIONS, getConditions);
  yield takeLatest(types.GET_CONDITION, getCondition);
  yield takeLatest(types.CREATE_CONDITION, createCondition);
  yield takeLatest(types.UPDATE_CONDITION, updateCondition);
  yield takeLatest(types.DELETE_CONDITION, deleteCondition);
  yield takeLatest(types.GET_EXCLUSIONS, getExclusions);
  yield takeLatest(types.GET_EXCLUSION, getExclusion);
  yield takeLeading(types.CREATE_EXCLUSION, createExclusion);
  yield takeLatest(types.UPDATE_EXCLUSION, updateExclusion);
  yield takeLatest(types.DELETE_EXCLUSION, deleteExclusion);
}
