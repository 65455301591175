const PRODUCTS = '[CMS_PRODUCTS]';

const types = {
  GET_PRODUCTS: `${PRODUCTS} GET_PRODUCTS`,
  SET_PRODUCTS: `${PRODUCTS} SET_PRODUCTS`,

  GET_PRODUCT: `${PRODUCTS} GET_PRODUCT`,
  SET_PRODUCT: `${PRODUCTS} SET_PRODUCT`,
  CLEAR_PRODUCT_DATA: `${PRODUCTS} CLEAR_PRODUCT_DATA`,
  GET_PRODUCT_ERROR: `${PRODUCTS} GET_PRODUCT_ERROR`,

  SET_PARAMS: `${PRODUCTS} SET_PARAMS`,
  DELETE_PARAMS: `${PRODUCTS} DELETE_PARAMS`,

  CREATE_PRODUCT_REQUEST: `${PRODUCTS} CREATE_PRODUCT_REQUEST`,
  CREATE_PRODUCT_SUCCESS: `${PRODUCTS} CREATE_PRODUCT_SUCCESS`,
  CREATE_PRODUCT_ERROR: `${PRODUCTS} CREATE_PRODUCT_ERROR`,

  UPDATE_PRODUCT_REQUEST: `${PRODUCTS} UPDATE_PRODUCT_REQUEST`,
  UPDATE_PRODUCT_SUCCESS: `${PRODUCTS} UPDATE_PRODUCT_SUCCESS`,
  UPDATE_PRODUCT_ERROR: `${PRODUCTS} UPDATE_PRODUCT_ERROR`,

  CREATE_BRAND: `${PRODUCTS} CREATE_BRAND`,
  CREATE_BRAND_ERROR: `${PRODUCTS} CREATE_BRAND_ERROR`,

  CREATE_DISCOUNT_REQUEST: `${PRODUCTS} CREATE_DISCOUNT_REQUEST`,
  UPDATE_DISCOUNTS_REQUEST: `${PRODUCTS} UPDATE_DISCOUNTS_REQUEST`,
  DELETE_DISCOUNT_REQUEST: `${PRODUCTS} DELETE_DISCOUNT_REQUEST`,

  GET_PHOTOS: `${PRODUCTS} GET_PHOTOS`,
  GET_STICKER: `${PRODUCTS} GET_STICKER`,
  GET_CATALOG_STICKER: `${PRODUCTS} GET_CATALOG_STICKER`,
  SET_PHOTOS: `${PRODUCTS} SET_PHOTOS`,
  SET_STICKER: `${PRODUCTS} SET_STICKER`,
  SET_CATALOG_STICKER: `${PRODUCTS} SET_CATALOG_STICKER`,
  SEND_PHOTOS_REQUEST: `${PRODUCTS} SEND_PHOTOS_REQUEST`,
  CREATE_MAIN_PHOTO: `${PRODUCTS} CREATE_MAIN_PHOTO`,
  REMOVE_PHOTO: `${PRODUCTS} REMOVE_PHOTO`,

  // Attributes
  GET_ATTRIBUTES: `${PRODUCTS} GET_ATTRIBUTES`,
  SET_ATTRIBUTES: `${PRODUCTS} SET_ATTRIBUTES`,

  CREATE_ATTRIBUTE_REQUEST: `${PRODUCTS} CREATE_ATTRIBUTE_REQUEST`,
  ADD_ATTRIBUTE_REQUEST: `${PRODUCTS} ADD_ATTRIBUTE_REQUEST`,
  UPDATE_ATTRIBUTE_STATUS: `${PRODUCTS} UPDATE_ATTRIBUTE_STATUS`,
  UPDATE_ATTRIBUTE_VALUES: `${PRODUCTS} UPDATE_ATTRIBUTE_VALUES`,
  UPDATE_ATTRIBUTE_VALUES_SUCCESS: `${PRODUCTS} UPDATE_ATTRIBUTE_VALUES_SUCCESS`,

  CREATE_ATTRIBUTE_VALUE_REQUEST: `${PRODUCTS} CREATE_ATTRIBUTE_VALUE_REQUEST`,
  ADD_NEW_ATTRIBUTE_VALUE: `${PRODUCTS} ADD_NEW_ATTRIBUTE_VALUE`,
  SET_NEW_VALUE_NAME: `${PRODUCTS} SET_NEW_VALUE_NAME`,
  GET_NEW_VALUE_NAME: `${PRODUCTS} GET_NEW_VALUE_NAME`,
  CLEAR_NEW_VALUE_NAME: `${PRODUCTS} CLEAR_NEW_VALUE_NAME`,

  GET_CATEGORY_ATTRIBUTES: `${PRODUCTS} GET_CATEGORY_ATTRIBUTES`,
  SET_CATEGORY_ATTRIBUTES: `${PRODUCTS} SET_CATEGORY_ATTRIBUTES`,
  CLEAR_CATEGORY_ATTRIBUTES: `${PRODUCTS} CLEAR_CATEGORY_ATTRIBUTES`,
  GET_CATEGORY_ATTRIBUTES_ERROR: `${PRODUCTS} GET_CATEGORY_ATTRIBUTES_ERROR`,

  GET_ATTRIBUTE_VALUES: `${PRODUCTS} GET_ATTRIBUTE_VALUES`,
  SET_ATTRIBUTE_VALUES: `${PRODUCTS} SET_ATTRIBUTE_VALUES`,

  // Mass update
  MASS_ACTIVATE_ATTRIBUTES_REQUEST: `${PRODUCTS} MASS_ACTIVATE_ATTRIBUTES_REQUEST`,
  MASS_DEACTIVATE_ATTRIBUTES_REQUEST: `${PRODUCTS} MASS_DEACTIVATE_ATTRIBUTES_REQUEST`,

  DISCONNECT_PRODUCT: `${PRODUCTS} DISCONNECT_PRODUCT`,

  GET_PASS_PRODUCTS: `${PRODUCTS} GET_PASS_PRODUCTS`,
  SET_PASS_PRODUCTS: `${PRODUCTS} SET_PASS_PRODUCTS`,
  POST_PASS_PRODUCTS: `${PRODUCTS} POST_PASS_PRODUCTS`,

  UPDATE_DESCRIPTIONS: `${PRODUCTS} UPDATE_DESCRIPTIONS`,
  MASS_UPDATE_DISCOUNTS: `${PRODUCTS} MASS_UPDATE_DISCOUNTS`,
  UPLOAD_STICKER: `${PRODUCTS} UPLOAD_STICKER`,
  UPLOAD_ACTION: `${PRODUCTS} UPLOAD_ACTION`,
  UPLOAD_ONE_STICKER: `${PRODUCTS} UPLOAD_ONE_STICKER`,
  REMOVE_STICKER: `${PRODUCTS} REMOVE_STICKER`,

  UPLOAD_CATALOG_STICKER: `${PRODUCTS} UPLOAD_CATALOG_STICKER`,
  REMOVE_CATALOG_STICKER: `${PRODUCTS} REMOVE_CATALOG_STICKER`,

  SET_SPEC_DEALER_PRICE: `${PRODUCTS} SET_SPEC_DEALER_PRICE`,

  ADD_PROMO_CODE: `${PRODUCTS} ADD_PROMO_CODE`
};

export default types;
