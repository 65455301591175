const PRODUCTS = '[CMS_VISIBLE_ON_SITE]';

const types = {
  GET_PRODUCTS: `${PRODUCTS} GET_PRODUCTS`,
  SET_PRODUCTS: `${PRODUCTS} SET_PRODUCTS`,

  SET_PARAMS: `${PRODUCTS} SET_PARAMS`,
  DELETE_PARAMS: `${PRODUCTS} DELETE_PARAMS`,

  UPDATE_DESCRIPTIONS: `${PRODUCTS} UPDATE_DESCRIPTIONS`,
  UPDATE_DESCRIPTIONS_SUCCESS: `${PRODUCTS} UPDATE_DESCRIPTIONS_SUCCESS`,

  MASS_ACTIVATE_ATTRIBUTES_REQUEST: `${PRODUCTS} MASS_ACTIVATE_ATTRIBUTES_REQUEST`,
  MASS_ACTIVATE_ATTRIBUTES_SUCCESS: `${PRODUCTS} MASS_ACTIVATE_ATTRIBUTES_SUCCESS`,

  MASS_DEACTIVATE_ATTRIBUTES_REQUEST: `${PRODUCTS} MASS_DEACTIVATE_ATTRIBUTES_REQUEST`,
  MASS_DEACTIVATE_ATTRIBUTES_SUCCESS: `${PRODUCTS} MASS_DEACTIVATE_ATTRIBUTES_SUCCESS`,

  DISCONNECT_PRODUCT: `${PRODUCTS} DISCONNECT_PRODUCT`,
  DISCONNECT_PRODUCT_SUCCESS: `${PRODUCTS} DISCONNECT_PRODUCT_SUCCESS`,

  MASS_UPDATE_DISCOUNTS: `${PRODUCTS} MASS_UPDATE_DISCOUNTS`,

  UPLOAD_STICKER: `${PRODUCTS} UPLOAD_STICKER`,
  UPLOAD_ACTION: `${PRODUCTS} UPLOAD_ACTION`,

  ADD_PROMO_CODE: `${PRODUCTS} ADD_PROMO_CODE`
};

export default types;
